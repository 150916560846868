const Parallax = {  
  init(el, displace)  {
    this.animateItem(el, displace);
  },
  setPosition() {
    if (window.pageYOffset !== undefined) {
      return window.pageYOffset;
    } else {
      return (document.documentElement || document.body.parentNode || document.body).scrollTop;
    }
  },
  animateItem(el, displace) {
    if (typeof window.orientation !== 'undefined') { return; }
    let scrollPosition = this.setPosition();
    el.style.transform = "translate3d(0px, "+(scrollPosition / displace)+"px, 0px)";
  }
}


export const handleScrolling = () => {
  const parallaxItems = document.querySelectorAll('[data-parallax]');
  parallaxItems.forEach(item => Parallax.init(item, item.dataset.parallax));
}

export default Parallax;

