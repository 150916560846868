import Parallax, {handleScrolling} from './libs/parallax'

if (document.querySelector('[data-parallax]')) {
  window.addEventListener('scroll', handleScrolling);
}


// smooth scroll
const scrollFunc = element => {
  element.addEventListener('click', e => {
    e.preventDefault();
    document.querySelector(element.getAttribute('href')).scrollIntoView({
      behavior: 'smooth'
    });
  });
};

const navLinks = document.querySelectorAll('.resources-nav a');
navLinks.forEach(scrollFunc);

const toTopLinks = document.querySelectorAll('.back-to-top');
toTopLinks.forEach(scrollFunc);


// mobile nav
const trigger = document.querySelector('.nav-trigger');
const nav = document.querySelector('nav');
trigger.addEventListener('click', () => {
  nav.classList.toggle('translate-x-full');
});



// lazy loading images
const images = document.querySelectorAll('[data-src]');
const imgOptions = {};
const imageOberserver = new IntersectionObserver((entries, imageOberserver) => {
  entries.forEach(entry => {
    if (!entry.isIntersecting) return
      preloadImage(entry.target);
      imageOberserver.unobserve(entry.target);
  });
}, imgOptions);

const preloadImage = img => {
  const src = img.getAttribute('data-src');
  img.parentElement.parentElement.classList.add('fade-in')
  if (!src) return;
  img.src = src;
}

images.forEach(img => {
  imageOberserver.observe(img);
})
